.App {
  background-color: #131313;
  flex-direction: column;
  display: inline-flex;
}


.App-herolines {
  background-image: url("./assets/herolines.png");
  background-position: top;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100vw 100%;
}

@media screen and (max-width: 600px) {
  .App-herolines {
    background-size: contain !important;
  }
}

.app-Light {
  right: 0;
}

@media screen and (max-width: 600px) {

  .App-herolines {
    display: none !important;
  }

  .app-Light {
    left: 50px;
  }

  .marketplace_cards {
    flex-direction: column;
  }

  .space {
    margin-bottom: 20px !important;
    justify-content: space-evenly !important;
  }

  .headNumber {
    font-size: 50px !important;
  }

  .subHeading {
    font-size: 18px !important;
  }
}

.BG {
  background-image: url("./assets/how_it_works_BG.png");
  background-position: top;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-mover {
  opacity: 0.5;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}